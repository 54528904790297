<script>
import { catalogoDeOpciones } from "./catalogoDeOpciones.js";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      catalogoDeOpciones: catalogoDeOpciones,
      catalogoDeOpcionesComplemento: [
        { name: "accessory", text: "Accesorios", selected: "" },
        { name: "face", text: "Cara", selected: "" },
        { name: "head", text: "Pelo", selected: "" },
        { name: "facialHair", text: "Bello facial", selected: "" },
        { name: "body", text: "Cuerpo", selected: "" },
      ],
      selectedCatalogo: "accessory",
      isCharacter: 1, // colors
      catalogoDeOpcionesColores: [
        {
          name: "hat",
          text: "Gorro",
          className: "--peep-hat-color",
          selected: "#fff",
        },
        {
          name: "hair",
          text: "Pelo",
          className: "--peep-hair-color",
          selected: "#fff",
        },
        {
          name: "skin",
          text: "Piel",
          className: "--peep-skin-color",
          selected: "#fff",
        },
        {
          name: "accessory",
          text: "Accesorio",
          className: "--peep-accessory-color",
          selected: "#fff",
        },
        {
          name: "facialHair",
          text: "Bello facial",
          className: "--peep-facial-hair-color",
          selected: "#fff",
        },
        {
          name: "clothes",
          text: "Ropa",
          className: "--peep-clothes-color",
          selected: "#fff",
        },
        {
          name: "object",
          text: "Objeto",
          className: "--peep-object-color",
          selected: "#fff",
        },
      ],
      selectedCatalogoColor: "hat",
      showResult: true,
      loading: false,
    };
  },
  computed: {
    resultCatalogo() {
      return this.catalogoDeOpcionesComplemento
        .map((item) => item.selected)
        .join(" ");
    },
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
    colors() {
      return {
        hat: this.catalogoDeOpcionesColores[0].selected,
        hair: this.catalogoDeOpcionesColores[1].selected,
        skin: this.catalogoDeOpcionesColores[2].selected,
        accessory: this.catalogoDeOpcionesColores[3].selected,
        facialHair: this.catalogoDeOpcionesColores[4].selected,
        clothes: this.catalogoDeOpcionesColores[5].selected,
        object: this.catalogoDeOpcionesColores[6].selected,
      };
    },
  },
  mounted() {
    this.$store.getters
      .fetchGet({ path: `Persona/GetPersonaById/${this.userLoggedIn.id}` })
      .then((response) => response.json())
      .then((result) => {
        if (result.stringAvatar) {
          this.setAvatarFrom(result.stringAvatar);
        } else {
          this.randomize();
        }
      })
      .catch(() => {
        this.randomize();
      });

    this.$watch(
      () => this.catalogoDeOpcionesColores,
      () => {
        this.showResult = false;
        this.$nextTick(() => {
          this.showResult = true;
        });
      },
      { deep: true }
    );
  },
  methods: {
    setAvatarFrom(avatarString) {
      const objAvatar = JSON.parse(avatarString);
      const newObject = window.structuredClone(
        this.catalogoDeOpcionesComplemento
      );
      newObject.forEach((item) => {
        item.selected = objAvatar[item.name];
      });
      this.catalogoDeOpcionesComplemento = newObject;
      console.log(this.catalogoDeOpcionesComplemento);

      const newObjectColors = window.structuredClone(
        this.catalogoDeOpcionesColores
      );
      newObjectColors.forEach((item) => {
        item.selected = objAvatar[item.className];
      });
      this.catalogoDeOpcionesColores = newObjectColors;
    },
    handleClickedCatalogoItem(item, nameElement) {
      const newObject = window.structuredClone(
        this.catalogoDeOpcionesComplemento
      );
      const index = newObject.findIndex(
        (element) => element.name === nameElement
      );
      newObject[index].selected = item;
      this.catalogoDeOpcionesComplemento = newObject;
    },
    randomize() {
      // randomize
      const copyCatalogo = window.structuredClone(
        this.catalogoDeOpcionesComplemento
      );
      const namesCatalogo = copyCatalogo.map((item) => item.name);

      namesCatalogo.forEach((name) => {
        const randomIndex = Math.floor(
          Math.random() * this.catalogoDeOpciones[name].length
        );
        const randomItem = this.catalogoDeOpciones[name][randomIndex];
        const index = copyCatalogo.findIndex((item) => item.name === name);
        copyCatalogo[index].selected = randomItem;
      });
      this.catalogoDeOpcionesComplemento = copyCatalogo;
    },
    handleClickedColor({ event, color }) {
      if (event) {
        color = event.target.value;
      }

      const newObject = window.structuredClone(this.catalogoDeOpcionesColores);
      const index = newObject.findIndex(
        (element) => element.name === this.selectedCatalogoColor
      );
      newObject[index].selected = color;
      this.catalogoDeOpcionesColores = newObject;
    },
    async saveAvatar() {
      const dataClass = {
        ...this.catalogoDeOpcionesComplemento.reduce((acc, item) => {
          acc[item.name] = item.selected;
          return acc;
        }, {}),
        ...this.catalogoDeOpcionesColores.reduce((acc, item) => {
          acc[item.className] = item.selected;
          return acc;
        }, {}),
      };

      const data = {
        StringAvatar: JSON.stringify(dataClass),
      };

      this.loading = true;

      const response = await this.$store.getters.fetchPut({
        path: `Persona/SetAvatar/${this.userLoggedIn.id}`,
        data,
      });
      const result = await response.json();
      console.log(result);
      if (response.ok) {
        Swal.fire({
          title: "Avatar guardado",
          text: "Tu avatar ha sido guardado con éxito",
          icon: "success",
          confirmButtonText: "Ok",
        });
        localStorage.setItem("stringAvatar", JSON.stringify(dataClass));
      } else {
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido un error al guardar tu avatar",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }

      this.loading = false;
    },
  },
};
</script>

<template>
  <HXContentCard title="Creación de Avatar" :defaultToBack="true">
    <header class="mt-3 mx-3 btn-group">
      <b-button
        :variant="isCharacter ? 'primary' : 'dark'"
        size="lg"
        @click="isCharacter = 1"
      >
        <i class="fas fa-user fa-2x"></i>
        <span class="h3" :class="isCharacter ? 'text-white' : ''"
          >Personaje</span
        >
      </b-button>
      <b-button
        :variant="!isCharacter ? 'primary' : 'dark'"
        size="lg"
        @click="isCharacter = 0"
      >
        <i class="fas fa-brush fa-2x"></i>
        <span class="h3" :class="!isCharacter ? 'text-white' : ''"
          >Colores</span
        >
      </b-button>
    </header>
    <section class="body-sections">
      <!-- 2 sub secciones -->
      <div class="first-section">
        <!-- personaje -->
        <div v-show="isCharacter">
          <b-button
            v-for="{ name, text } in catalogoDeOpcionesComplemento"
            class="w-100 my-1"
            :variant="name == selectedCatalogo ? 'primary' : 'dark'"
            :key="name + 'section'"
            @click="selectedCatalogo = name"
          >
            {{ text }}
          </b-button>
        </div>
        <!-- colores -->
        <div v-show="!isCharacter">
          <b-button
            v-for="{ name, text } in catalogoDeOpcionesColores"
            class="w-100 my-1"
            :variant="name == selectedCatalogoColor ? 'primary' : 'dark'"
            :key="name + 'section'"
            @click="selectedCatalogoColor = name"
          >
            {{ text }}
          </b-button>
        </div>
        <b-button variant="success" @click="saveAvatar" :loading="loading">
          <span v-show="!loading" style="color: #fff">Guardar cambios</span>
          <span v-show="loading">
            <i class="fas fa-spinner fa-spin-pulse fa-1x text-dark"></i>
          </span>
        </b-button>
      </div>

      <!-- 2 catalogos -->
      <div class="catalogo" v-show="isCharacter">
        <div
          v-for="item in catalogoDeOpciones[selectedCatalogo]"
          class="catalogo-item card bg-dark d-flex justify-content-center align-items-center"
          :class="{
            showHead: selectedCatalogo != 'body',
            isBody: selectedCatalogo == 'body',
            active:
              item ==
              catalogoDeOpcionesComplemento.find(
                (i) => i.name == selectedCatalogo
              ).selected,
          }"
          :key="item"
          @click="() => handleClickedCatalogoItem(item, selectedCatalogo)"
          :data-css-peeps="
            item != '<url>'
              ? `${item} ${
                  selectedCatalogo != 'head'
                    ? catalogoDeOpcionesComplemento[2].selected
                    : ''
                }`
              : ''
          "
        >
          <i v-if="item.includes('url')" class="fas fa-ban fa-2x"></i>
          {{ item.includes("url") ? "Ninguno" : "" }}
        </div>
      </div>
      <div v-show="!isCharacter">
        <p class="mb-2 h4">
          No todas las características del catalogo puede tener un color
          arbitrario.
        </p>
        <input
          type="color"
          @change="($event) => handleClickedColor({ event: $event })"
        />
      </div>

      <div class="wrapper-result">
        <div
          :data-css-peeps="resultCatalogo"
          class="miAvatar"
          v-if="showResult"
        ></div>
      </div>
    </section>
  </HXContentCard>
</template>

<style scoped>
h3 {
  text-align: center;
  padding: 0.25rem 0;
}

.first-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.body-sections {
  padding: 1rem;
  display: grid;
  grid-template-columns: 200px 1fr 1fr;
  gap: 1rem;
  height: 60vh;
}

.catalogo {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-template-rows: repeat(auto-fill, 120px);
  gap: 0.5rem;
  /* height: 500px; */
  overflow-y: scroll;
}

.catalogo-item {
  grid-column: span 1;
  grid-row: span 1;
  height: 120px;
  width: auto;
  --peep-zoom: 2;
  cursor: pointer;

  &.active,
  &:hover {
    box-shadow: inset 0 0 0 2px #1a5cff;
  }
}

.showHead {
  --peep-body-offset-x: 10px;
  --peep-body-offset-y: 40px;
}

.isBody {
  --peep-body-offset-x: 0px;
  --peep-body-offset-y: 0px;
  --peep-zoom: 1;
}

.wrapper-result {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: solid 1px #000; */
}

.miAvatar {
  /* border: solid 1px #000; */
  width: 320px;
  height: 320px;
}

* {
  --peep-hat-color: v-bind(colors.hat);
  --peep-hair-color: v-bind(colors.hair);
  --peep-skin-color: v-bind(colors.skin);
  --peep-accessory-color: v-bind(colors.accessory);
  --peep-facial-hair-color: v-bind(colors.facialHair);
  --peep-object-color: v-bind(colors.object);
  --peep-clothes-color: v-bind(colors.clothes);
  --peep-flipped: 100;
}
</style>
